<template>
  <input
    v-if="useAutocomplete"
    id="pac-input"
    class="pac-input"
    :placeholder="validPlaceholder"
    :value="initialPlace?.formatted_address"
  />

  <div id="google-map" style="width: 758px; height: 435px"></div>

  <div id="infowindow-content"></div>
</template>

<script setup lang="ts">
  const props = defineProps<{
    placeAutocompleteInvalid?: boolean;
    initialPlace?: google.maps.places.PlaceResult;
    useAutocomplete?: boolean;
  }>();

  const useGoogleMap = useGoogleMapStore();
  const {createGoogleMap} = useGoogleMap;
  const {t} = useI18n();

  const validPlaceholder = t('components.GoogleMap.address_placeholder');
  const invalidPlaceholder = t(
    'components.GoogleMap.addressInvalid_placeholder',
  );

  let autoCompleteInput: HTMLInputElement;

  watch(
    () => props.placeAutocompleteInvalid,
    () => {
      const invalid = props.placeAutocompleteInvalid;

      autoCompleteInput.setAttribute(
        'placeholder',
        invalid ? invalidPlaceholder : validPlaceholder,
      );

      invalid
        ? autoCompleteInput.classList.add('invalid')
        : autoCompleteInput.classList.remove('invalid');
    },
  );

  onMounted(() => {
    autoCompleteInput = document.getElementById(
      'pac-input',
    ) as HTMLInputElement;

    const mapContainer = document.getElementById(
      'google-map',
    ) as HTMLDivElement;

    createGoogleMap({
      mapContainer,
      initialPlace: props.initialPlace,
      useAutocomplete: props.useAutocomplete,
    });
  });
</script>

<style lang="scss" scoped>
  @import 'themes/mytheme/variables';

  .pac-input {
    background-color: #fff;
    border-radius: 2px;
    border: 2px solid rgba($warningColor, 0.75);
    box-shadow: 0 2px 6px rgb(0 0 0 / 30%);
    box-sizing: border-box;
    font-size: 15px;
    font-weight: 300;
    height: 40px;
    margin-left: 17px;
    margin-top: 10px;
    outline: none;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 400px;

    @media screen and (width <= 728px) {
      width: 220px;
    }
  }

  .pac-input:focus {
    border-color: $primaryColor;
  }

  .pac-input.invalid {
    &:not(:focus) {
      border-color: $errorColor;

      &::placeholder {
        color: $errorColor;
      }
    }
  }

  #infowindow-content {
    display: inline;
  }

  #pac-input {
    position: absolute;
    left: -1000px;
  }
</style>
