<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7.5" cy="7.5" r="7.5" fill="#F44236" />
    <g clip-path="url(#clip0_0_1)">
      <path
        d="M10.8546 9.2492L8.38179 4.70362C7.9845 4.03478 7.01603 4.03389 6.6182 4.70362L4.14556 9.2492C3.73943 9.93264 4.23114 10.7981 5.02715 10.7981H9.97276C10.7681 10.7981 11.2607 9.93334 10.8546 9.2492ZM7.5 9.97778C7.27389 9.97778 7.08984 9.79373 7.08984 9.56763C7.08984 9.34152 7.27389 9.15747 7.5 9.15747C7.7261 9.15747 7.91015 9.34152 7.91015 9.56763C7.91015 9.79373 7.7261 9.97778 7.5 9.97778ZM7.91015 8.33716C7.91015 8.56326 7.7261 8.74731 7.5 8.74731C7.27389 8.74731 7.08984 8.56326 7.08984 8.33716V6.28638C7.08984 6.06027 7.27389 5.87622 7.5 5.87622C7.7261 5.87622 7.91015 6.06027 7.91015 6.28638V8.33716Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_1">
        <rect width="7" height="7" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </svg>
</template>
